/* Styling for overall html */

html {
  font-family:'Bungee', 'Open Sans', Geneva, Verdana, sans-serif;
  font-size: 3vw;
}

/* tablet */
@media only screen and (min-width: 480px) {
  html {
      font-size: 16px;
  }
}

/* desktop */
@media only screen and (min-width: 768px) {
  html {
      font-size: 18px;
  }

}

/* extra large */
@media only screen and (min-width: 1024px) {
  html {
      font-size: 20px;
  }
}

@media only screen and (min-width: 1600px) {
  html {
      font-size: 24px;
  }
}

p {
  text-indent: 20pt;
}

h1 {
  font-family : "Bungee";
  text-align: center;
  font-size:2rem;
}

h2 {
  font-family : "Bungee";
  text-align: center;
  font-size:1.5rem;
}

h3 {
  font-family : "Roboto", monospace;;
  font-weight : bolder;
  font-size: 1.1rem;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }


.cta {
  font-family:"Roboto"
}


/* Homepage styling */
.blur-on {
  width : 100vw;
  height : 100vh;
  backdrop-filter: blur(0.2rem);
  position: absolute;
  top:0;
  left:0;
  transition: background-color 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out;
  background-color:rgba(0, 0, 0, 0.522);
}
.blur-off {
  width : 100vw;
  height : 100vh;
  position: absolute;
  backdrop-filter: blur(0.0rem);
  top:0;
  left:0;
  transition: background-color 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out;
}

.Welcome-Text {
  padding:4rem;
  padding-top:0;
  top:0;
  font-family:'Inconsolata';
  opacity: 100%;
  transition: transform 1s ease-in-out, opacity 1s ease-in;
  transition-delay: 0.2s;
  transform: translateY(0vh);
  text-align: center;
  font-size: 1.5rem;
}

.Welcome-Text-hidden {
  padding:4rem;
  padding-top:0;
  top:0;
  font-family:'Inconsolata', monospace;
  opacity: 0%;
  transition: transform 1s ease-in-out, opacity 1s ease-in;
  transform: translateY(5vh);
  text-align: center;
  font-size: 1.5rem;
}

.Logo {
  position: absolute;
  height: 10rem;
  width: 10rem;
  background: url("../assets/synaptechLogos/dark_synaptech_logo_transparent.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 1rem;
  display:flex;
  left:-1rem;
  top:-3rem;
}

.BodyBox-Left {
  display: flex; 
  flex-direction: column; 
  padding:4rem; 
  padding-left:10rem; 
  width: 50vw;
  text-align: left;
}

.BodyBox-Right {
  display: flex;
  flex-direction: column;
  padding: 4rem;
  padding-right: 10rem;
  width: 50vw;
  text-align: left;
}

.BodyBox-Mobile {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: left;
}

.Navbar-Link {
  background:none;
  border: none;
  color: white;
  padding:1rem;
}

.app-title {
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  font-family:"Bungee";
  right:2rem;
  align-items: right;
}

.Header {
  position: fixed;
  display:flex;
  height: 4rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;
  z-index:10000;
  transition-property: height;
  transition: transform 1s ease-in-out;
  transform: translateY(0vh);
}

.Header-Hidden {
  position: fixed;
  height: 4rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:row;
  z-index:10000;
  transition-property: height;
  transition: transform 1s ease-in-out;
  transform: translateY(-5rem);
  pointer-events: none;
}

.Welcome {
  position:fixed;
  text-align: center;
  font-size: 1rem;
  background-color: rgba(10, 21, 44, 0.616);
  backdrop-filter: blur(0.5rem);
  min-height: 100vh;
  min-width: 100vw;
  transition: opacity 0.5s ease-in-out;
  opacity:100%;
  z-index: 9999;
  pointer-events: none;
}

.Welcome-Scrolled {
  position:fixed;
  text-align: center;
  font-size: 1rem;
  background-color: rgba(10, 21, 44, 0.616);
  backdrop-filter: blur(0.5rem);
  min-height: 100vh;
  min-width: 100vw;
  transition: opacity 0.5s ease-in-out;
  opacity:0%;
  z-index: 0;
  pointer-events: none;
}

.Body-default{
  color:white;
  text-align: center;
  min-height: 50vh;
  height: fit-content;
  width: 100%;
}

.Body-cols {
  color:white;
  text-align: center;
  min-height: 50vh;
  height: fit-content;
  width: 100%;
  display: flex; 
  flex-direction: row;
  text-align:left;
}

.Body-rows {
  color:white;
  text-align: center;
  min-height: 50vh;
  height: fit-content;
  width: 100%;
  display: flex; 
  flex-direction: column;
  text-align:left;
}


.BodyText-Mobile {
  text-align: left;

}

.test{
  color:white;
  min-height: 50vh;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.test-locked{
  color:white;
  min-height: 50vh;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position:fixed;
  overflow:hidden;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.App-logo {
  height: 35vw;
  width: 35vw;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  min-height:100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index : 100;
  width: 100vw;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Background styling */
.ThreeScene {
  max-width:2560px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index : -1;
}

.BrainWindow {
  /* height: 150vh; */
  height : 150vh;
  border-color: white;
  border-style: none;
  }

.wire {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.backgroundPath {
  stroke: transparent;
  stroke-width: 1px;
  fill: none;
}

.linkPath {
  stroke: rgb(255, 255, 162);
  stroke-width: 0.3rem;
  fill: none;
}

.storeText {
  position:absolute;
  height: fit-content;
  display:flex;
  flex-direction: column;
  /* width: 25rem; */
  /* height: 80%; */
  /* width: 33%; */
  border-style:solid;
  border-color:rgb(255, 255, 162);
  border-width: 0;
  /* border-left-width: 1rem;
  border-left-color:rgb(255, 255, 162);
  border-right-width: 0.1rem;
  border-right-color:white; */
  border-bottom-width: 1rem;
  border-bottom-color:rgb(255, 255, 162);
  border-top-width: 0.1rem;
  border-top-color:white;
  background-color: rgba(7, 14, 29, 0.719);
  color: white;
  padding: 1rem;
  padding-top:1rem;
  backdrop-filter: blur(7px);
  z-index:9999999999;
  transition: transform 1s ease-in-out;
  transition-delay: 5ms;
  transform: rotateX(0deg);
  transform-origin: left bottom;
}

.storeText-hidden {
  position:absolute;
  height: fit-content;
  display:flex;
  overflow: hidden;
  flex-direction: column;
  right : 10vw;
  border-style:solid;
  border-color:rgb(255, 255, 162);
  border-width: 0;
  border-left-width: 1rem;
  border-left-color:rgb(255, 255, 162);
  border-right-width: 0.1rem;
  border-right-color:white;
  background-color: rgba(7, 14, 29, 0.719);
  color: white;
  padding: 1rem;
  backdrop-filter: blur(7px);
  z-index:200;
  transition: transform 0s;
  transform: rotateX(90deg);
  transform-origin:left bottom;
}

.storeTextTitle {
  border-color: rgb(47, 141, 218);
  border-width: 0;
  border-bottom-width:  0.3rem;
  border-style: solid;
}

.social-logo {
  width: 2rem;
  height: 2rem;
  padding:0.1rem;
}


.sponsor-image-large {
  width: 80vw;
  height: 20vw;
 
}

.sponsor-image-small {
  width: 30vw;
  height: 7vw;
 
}

.main-lists{
  text-align: center;
  list-style-type: none;
  margin-left: 0;
  padding: 0;
}

#formlink{
  color: lightblue
}