/* Styling for overall html */

.AdminContainer {
  padding-bottom: 2rem;
}

.AdminContainer h1, .AlumniContainer h1 {
  font-size: 2rem;
}

.AdminContainer h2 {
  font-size: 1.3rem;
  font-family: 'Open Sans', sans-serif;
}

.AdminContainer h3 {
  font-size: 1rem;
  color: #9E9577;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.AdminContainer h4 {
  font-size: 0.8rem;
  color: #9E9577;
  margin-top: 0;
}

.AdminInfo {
  columns: 400px 2;
}

.AlumniContainer h2 {
  color: black;
  font-size: 1.3rem;
}

.AlumniContainer h3 {
  color: black;
  font-size: 1.1rem;
}

.AlumniContainer h4 {
  color: black;
  font-size: 0.9rem;
}

.AlumniContainer a {
  font-size: 0.9rem;
  color: #073763;
  text-decoration: none;
}

.hr {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 1px;
  background-color: rgb(51, 51, 51);
  margin-bottom: 2rem;
}

.EndBlurb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #073763;
  color: white;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}